const perguntas = [
  {
    questao: "Qual a região que você atende?",
    resposta:
      "Atendemos na região de Guarapuava-PR, contudo muitas pessoas encomendam antecipadamente e levam os bolos congelados para outras cidades e estados.",
  },
  {
    questao: "Eu tenho que buscar a encomenda ou posso pedir para entregar ?",
    resposta:
      "Não fazemos entregas a domicílio. Ocasionalmente entregamos em hotéis, clubes, etc.",
  },
  {
    questao: "Qual o horário de atendimento ?",
    resposta: "Das 09:00 da manhã as 20:00 da noite.",
  },
  {
    questao: "Você faz encomendas de docinhos e salgadinhos ?",
    resposta: "Não, somente faço bolos sob encomenda.",
  },
  {
    questao:
      "Posso alterar o ingrediente de algum bolo, ou pedir um sabor personalizado?",
    resposta: "Sim. O bolo pode ter recheios e ou cobertura personalizados.",
  },
  {
    questao: "Qual o telefone ?",
    resposta: "(42) 3623-3581",
  },
];

module.exports = perguntas;
