<template>
  <div>
    <PageHeader
      titleHeader="Perguntas"
      subtitleHeader="Quem sabe a sua dúvida já está respondida aqui"
    />
    <div class="row panel-perguntas">
      <div
        class="col-md-12 body-on"
        v-for="(pergunta, index) in perguntas"
        :key="index"
      >
        <div class="perguntas">
          <h3 style="font-weight: bolder">{{ pergunta.questao }}</h3>
          <p class="lead">
            {{ pergunta.resposta }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/layout/PageHeader";
export default {
  name: "Perguntas",
  data() {
    return {
      perguntas: require("@/scripts/perguntas.js"),
    };
  },
  components: {
    PageHeader,
  },
};
</script>
